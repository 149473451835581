// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-components-about-about-tsx": () => import("./../../src/pages/components/About/About.tsx" /* webpackChunkName: "component---src-pages-components-about-about-tsx" */),
  "component---src-pages-components-banner-banner-tsx": () => import("./../../src/pages/components/Banner/Banner.tsx" /* webpackChunkName: "component---src-pages-components-banner-banner-tsx" */),
  "component---src-pages-components-contact-contact-tsx": () => import("./../../src/pages/components/Contact/Contact.tsx" /* webpackChunkName: "component---src-pages-components-contact-contact-tsx" */),
  "component---src-pages-components-fa-qs-fa-qs-tsx": () => import("./../../src/pages/components/FAQs/FAQs.tsx" /* webpackChunkName: "component---src-pages-components-fa-qs-fa-qs-tsx" */),
  "component---src-pages-components-footer-footer-tsx": () => import("./../../src/pages/components/Footer/Footer.tsx" /* webpackChunkName: "component---src-pages-components-footer-footer-tsx" */),
  "component---src-pages-components-header-header-tsx": () => import("./../../src/pages/components/Header/Header.tsx" /* webpackChunkName: "component---src-pages-components-header-header-tsx" */),
  "component---src-pages-components-people-people-tsx": () => import("./../../src/pages/components/People/People.tsx" /* webpackChunkName: "component---src-pages-components-people-people-tsx" */),
  "component---src-pages-components-workshop-workshop-tsx": () => import("./../../src/pages/components/Workshop/Workshop.tsx" /* webpackChunkName: "component---src-pages-components-workshop-workshop-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

